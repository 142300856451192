.App {
  height: 100vh;
  display: grid;
  grid-template: auto 1fr auto / auto 1fr auto;
  overflow: hidden;
}
.header {
  grid-column: 1 / 4;
  z-index: 999;
}

.sidebar {
  grid-column: 1 / 1;
  width: 220px;
  min-width: 220px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.main {
  overflow: hidden auto;
  background-color: rgb(246, 245, 250);
  z-index: 10;
  padding: 1rem 1rem;
}
.softUi {
  background: #fff;
  box-shadow: 0 2px 6px #0000001a;
  padding: 1.5rem;
  border-radius: 10px;
  position: relative;
}
/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 640px) {
  .App {
    display: flex;
    flex-direction: column;
  }
  .footer {
    flex: 0 0 auto;
  }
  .header {
    flex: 0 0 auto;
  }

.main {
  flex: 1 1 auto;
  position: relative;/* need this to position inner content */
  overflow-y: auto;
  margin-bottom: 70px;

}
}

@media screen and (max-width: 640px) {
.main {
  margin-bottom: 70px;
}
}