.head {
  height: 60px;
  /* box-shadow: 0 1px 6px #0000001a; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 24px;
  z-index: 999;
}
.nav {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}
.navItem {
  font-size: 0.8rem;
  color: #333333;
}
.logo,
.user {
  flex: 1;
}
.logo {
  height: 10;
  display: inherit;
}
.logo img {
  margin-top: 0px;
  height: 100% !important;
}
.navButton {
  background: #2d77f4;
  color: #fff;
  padding: 0.5rem 0.8rem;
  border-radius: 5px;
  border: 1px solid #2d77f4;
  transition: all 0.3s ease-in-out;
  line-height: normal !important;
  font-size: 0.8rem;
}
.navButton:hover {
  background: #fff;
  color: #2d77f4;
}
.user {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  gap: 1.5rem;
}
.userInfo {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 0.5rem;
  cursor: pointer;
}
.banner {
  height: 50px;
  background: #2d77f4;
  box-sizing: border-box;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  overflow: hidden;
}

.notifications {
  height: 20px;
}
