@font-face {
  font-family: "Montserrat";
  src: url("../src/assets/fonts/Montserrat/Montserrat-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../src/assets/fonts/Montserrat/Montserrat-Bold.ttf");
  font-weight: bold;
}

/* @font-face {
  font-family: "Montserrat";
  src: url("../src/assets/fonts/Montserrat/Montserrat-Semibold.ttf");
  font-weight: 600;
} */

@font-face {
  font-family: "Montserrat";
  src: url("../src/assets/fonts/Montserrat/Montserrat-Medium.ttf");
  font-weight: 500;
}

/* @font-face {
  font-family: "Montserrat";
  src: url("../src/assets/fonts/Montserrat/Montserrat-Heavy.ttf");
  font-weight: 900;
} */

@font-face {
  font-family: "Passenger Sans";
  src: url("../src/assets/fonts/PassengerSans/PassengerSans-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Passenger Sans";
  src: url("../src/assets/fonts/PassengerSans/PassengerSans-Semibold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Passenger Sans";
  src: url("../src/assets/fonts/PassengerSans/PassengerSans-Bold.ttf");
  font-weight: bold;
}

* {
  font-family: "Montserrat" !important;
  font-weight: 500 !important;
}
body {
  margin: 0;
  /* font-family: "Montserrat", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  text-decoration: none;
}
.swiper-pagination-bullet {
  width: 3rem !important;
  height: 3px !important;
  border-radius: 0px !important;
}
.swiper-button-disabled {
  border: 1px solid #e5e5e5 !important;
  cursor: not-allowed !important;
}
.swiper-button-disabled svg {
  color: #e5e5e5 !important;
}

g circle {
  display: none !important;
}

#chakra-modal-tradeModal-custom {
  max-width: 1000px !important;
}

li[id^="order"] > div[data-highlighted],
li[id^="order"] > div:nth-child(2) {
  background-color: transparent !important;
  margin-left: 20px !important;
}

/* div[data-highlighted] {
  border-color: #00a3be !important;
  background-color: #00a3be !important;
}
div[aria-current="step"] {
  border-color: #00a3be !important;
} */

ol > li {
  height: 50px !important;
}
/* .chakra-stack > ol {
  flex: 0;
} */
.scrips-table::-webkit-scrollbar {
  width: 8px;
  position: absolute;
  overflow-y: overlay;
}

.scrips-table::-webkit-scrollbar-track {
  background-color: #f6f5fa;
}

.scrips-table::-webkit-scrollbar-thumb {
  background-color: #888;
}
div[id^="slider-mark"] {
  background-color: transparent !important;
}

.css-1ncbew8[aria-checked="true"],
.css-1ncbew8[data-checked] {
  background: #00a3be !important;
  border-color: #00a3be !important;
}

div[id^="highcharts"] {
  height: 468px !important;
}
div[id^="highcharts"] svg {
  height: 468px;
}
#none {
  display: none;
}

div[class$="ValueContainer"] > div[id$="placeholder"] {
  left: 1rem;
}

.chart_js {
  width: 100% !important;
  height: 100% !important;
}
.helper {
  --reactour-accent: #5cb7b7;
  line-height: 1.3;
  color: #2d2323;
}
.reactour__popover {
  padding: 1rem !important;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  max-width: unset !important;
  width: 400px;
}
.appended {
  /* position: absolute; */
  /* width: 29px;
  height: 100px;
  border: 15px solid #fff;
  top: -99px;
  left: 0px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent; */
}
#chakra-modal-tradeModal-custom-2 {
  max-width: 1400px !important;
}
